import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    target: String,
    tag: String,
    text: String
  }

  insert() {
    const text = this.selection ? `(${this.selection}) ${this.textValue}` : this.textValue
    this.insertText(`_${text}_ (${this.tagValue})`)
  }

  insertText(textToInsert) {
    const textarea = this.textarea
    const start = textarea.selectionStart
    const end = textarea.selectionEnd
    const text = textarea.value
    
    // Insert the text at the cursor position
    textarea.value = text.substring(0, start) + textToInsert + text.substring(end)


    // Update cursor position
    textarea.selectionStart = textarea.selectionEnd = start + textToInsert.length

    // Focus back on the textarea
    textarea.focus()
  }

  
  get textarea() {
    return document.querySelector(this.targetValue)
  }


  get selection() {
    if (this.textarea.selectionStart == this.textarea.selectionEnd ) return null
    return this.textarea.value.substring(this.textarea.selectionStart, this.textarea.selectionEnd)
  }
}
